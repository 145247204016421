import { useState, useEffect, useCallback, useMemo } from 'react';






function escapeRegex(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#]/g, '');
}


function bubbleSortAsc(data, accessor) {
  let newData = [...data];
  for (let i = 0; i < newData.length; i++) {
    for (let j = 0; j < i; j++) {
      const ith = newData[i];
      const jth = newData[j];
      if (ith[accessor] < jth[accessor]) {
        newData[i] = jth;
        newData[j] = ith;
      }
    }
  }
  return newData;
}

function bubbleSortDesc(data, accessor) {
  let newData = [...data];

  for (let i = 0; i < newData.length; i++) {
    for (let j = 0; j < i; j++) {
      const ith = newData[i];
      const jth = newData[j];
      if (ith[accessor] > jth[accessor]) {
        newData[i] = jth;
        newData[j] = ith;
      }
    }
  }
  return newData;
}
















export default function useTableHook(data, columns) {
  // Initialize column state with filter and sort properties
  const [newColumns, setNewColumns] = useState(
    columns.map(col => ({...col, filterValue: '', sort: ''}))
  );

  // Track unfiltered data
  const [unfilteredData, setUnfilteredData] = useState(data);
  useEffect(() => {
    setUnfilteredData(data);
  }, [data]);

  // Sorting functions
  const updateSort = useCallback((accessor, sortDir) => {
    const sortFn = sortDir === 'asc' ? bubbleSortAsc : bubbleSortDesc;
    const newData = sortFn(unfilteredData, accessor);
    setNewColumns(cols => cols.map(col => ({
      ...col,
      sort: col.accessor === accessor ? sortDir : ''
    })));
    setUnfilteredData(newData);
  }, [unfilteredData]);

  const sortAsc = useCallback((accessor) => updateSort(accessor, 'asc'), [updateSort]);
  const sortDesc = useCallback((accessor) => updateSort(accessor, 'desc'), [updateSort]);
  
  const customSort = useCallback(fn => {
    setUnfilteredData(fn(unfilteredData));
  }, [unfilteredData]);

  const clearSort = useCallback(() => {
    setNewColumns(cols => cols.map(col => ({...col, sort: ''})));
  }, []);

  // Filter state and functions
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [appliedFiltersMap, setAppliedFiltersMap] = useState({});

  useEffect(() => {
    const map = appliedFilters.reduce((acc, filter) => ({
      ...acc,
      [filter.accessor]: { value: filter.value, polarity: filter.polarity }
    }), {});
    setAppliedFiltersMap(map);
  }, [appliedFilters]);

  const columnFilterTypes = useMemo(() => 
    columns.reduce((acc, col) => ({
      ...acc, 
      [col.accessor]: col.filterType
    }), {}), 
  [columns]);

  const clearFilter = useCallback((accessor) => {
    setAppliedFilters(filters => filters.filter(f => f.accessor !== accessor));
    setNewColumns(cols => cols.map(col => ({
      ...col,
      filterValue: col.accessor === accessor ? '' : col.filterValue
    })));
  }, []);

  const clearAllFilters = useCallback(() => {
    setAppliedFilters([]);
    setNewColumns(cols => cols.map(col => ({...col, filterValue: ''})));
  }, []);

  const addFilter = useCallback((accessor, value, polarity=true, esc=false) => {
    if (!value) {
      clearFilter(accessor);
      return;
    }

    const filterValue = esc ? escapeRegex(value) : value;
    
    setAppliedFilters(filters => {
      const existing = filters.findIndex(f => f.accessor === accessor);
      if (existing >= 0) {
        return filters.map((f, i) => i === existing ? 
          {...f, value: filterValue, polarity} : f);
      }
      return [...filters, {accessor, value: filterValue, polarity}];
    });

    setNewColumns(cols => cols.map(col => ({
      ...col,
      filterValue: col.accessor === accessor ? value : col.filterValue
    })));
  }, [clearFilter]);

  // Visible data filtering
  const [visibleData, setVisibleData] = useState(data);

  useEffect(() => {
    const filterRow = (row) => {
      return appliedFilters.every(({accessor, value, polarity}) => {
        const rowValue = row[accessor];
        let result;

        switch (columnFilterTypes[accessor]) {
          case 'search':
            result = rowValue.toLowerCase().search(value.toLowerCase()) >= 0;
            break;
          case 'includes':
            result = !value.length || value.includes(rowValue);
            break;
          case 'between':
            const min = Number(value.min);
            const max = Number(value.max);
            result = (!min || rowValue >= min) && (!max || rowValue <= max);
            break;
          default:
            result = value === rowValue;
        }

        return polarity ? result : !result;
      });
    };

    setVisibleData(unfilteredData.filter(filterRow));
  }, [unfilteredData, appliedFilters, columnFilterTypes]);

  // Column totals
  const [columnTotals, setColumnTotals] = useState({});

  useEffect(() => {
    const totals = columns
      .filter(col => col.columnTotal)
      .reduce((acc, col) => ({
        ...acc,
        [col.accessor]: visibleData.reduce((sum, row) => 
          sum + (row[col.accessor] ?? 0), 0)
      }), {});
    
    setColumnTotals(totals);
  }, [columns, visibleData]);

  return {
    data: visibleData,
    columns: newColumns,
    columnTotals,
    sortAsc,
    sortDesc,
    customSort,
    clearSort,
    appliedFilters,
    appliedFiltersMap,
    addFilter,
    clearFilter,
    clearAllFilters
  };
}