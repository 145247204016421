import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import App from './Test';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { Provider } from 'react-redux';
import { Auth0Provider} from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

/*
 * Strict mode causes double renders.
 * Is it only in dev mode?
 * Why?
 */

//root.render(<React.StrictMode><App /></React.StrictMode>);
root.render(
  <Provider store={store}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{redirect_uri: window.location.origin}}
    >
      <App />
    </Auth0Provider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
