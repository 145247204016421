
export function Info({ setInfo, page }) {
  return (
    <div className="absolute top-0 z-40 h-full w-full flex flex-col justify-center bg-bgSpinner/50 backdrop-blur-sm">
      <div className="h-[50%] flex flex-row justify-center">
        <div className="w-[50%] p-[1em] bg-bgMain rounded-xl shadow-2xl">

          <div className="h-[10%] flex justify-end">
            <button className="h-[1.5em] w-[1.5em] bg-close bg-contain" onClick={setInfo}></button>
          </div>

          <div className="h-[90%] overflow-y-auto">
            {page === 'stock' ? <StockInfo /> :
            page === 'movements' ? <MovementsInfo /> :
            page === 'so-hdrs' ? <SalesOrderHeadersInfo /> :
            page === 'inv-hdrs' ? <InvoiceHeadersInfo /> :
            page === 'so-lines' ? <SalesOrderLinesInfo /> :
            page === 'inv-lines' ? <InvoiceLinesInfo /> :
            <></>}

          </div>


        </div>
      </div>
    </div>
  )
}


function StockInfo() {
  return (
    <>
      <div className="mb-[1em]">
        <h2 className="text-2xl mb-[1em]">Page Info</h2>
        <p>This page shows current stock levels.</p>
        <p>Data is updated daily at 8am, 11am, 3pm & 7pm.</p>
      </div>

      <div>
        <h2 className="text-2xl mb-[1em]">Using this page</h2>

        <ul className="list-disc list-inside mb-[1em]">
          <li>Use the collapsible table filters to filter the results</li>
          <li>Click on the table column headers to sort the table (ascending & descending)</li>
        </ul>

        <div className="flex justify-left">
          <div className="h-[1.5em] w-[1.5em] bg-refresh bg-contain mr-[.5em]"></div>
          Refresh the data
        </div>
        <div className="flex justify-left">
          <div className="h-[1.5em] w-[1.5em] bg-download bg-contain mr-[.5em]"></div>
          Export the data to a .csv file
        </div>
      </div>
    </>
  );
}


function MovementsInfo() {
  return (
    <>
      <div className="mb-[1em]">
        <h2 className="text-2xl mb-[1em]">Page Info</h2>
        <p>This page shows information about stock movements over the selected date-range.</p>
        <p>Data is updated daily at 8am, 11am, 3pm & 7pm.</p>
      </div>

      <div>
        <h2 className="text-2xl mb-[1em]">Using this page</h2>

        <ul className="list-disc list-inside mb-[1em]">
          <li>Use the collapsible table filters to filter the results</li>
          <li>Click on the table column headers to sort the table (ascending & descending)</li>
        </ul>

        <div className="flex justify-left">
          <div className="h-[1.5em] w-[1.5em] bg-calendar bg-contain mr-[.5em]"></div>
          Get data from a different date-range
        </div>
        <div className="flex justify-left">
          <div className="h-[1.5em] w-[1.5em] bg-refresh bg-contain mr-[.5em]"></div>
          Refresh the data
        </div>
        <div className="flex justify-left">
          <div className="h-[1.5em] w-[1.5em] bg-download bg-contain mr-[.5em]"></div>
          Export the data to a .csv file
        </div>
      </div>
    </>
  );
}


function SalesOrderHeadersInfo() {
  return (
    <>
      <div className="mb-[1em]">
        <h2 className="text-2xl mb-[1em]">Page Info</h2>
        <p>This page shows sales orders ordered within the selected date-range.</p>
        <p>Data is updated daily at 8am, 11am, 3pm & 7pm.</p>
      </div>

      <div>
        <h2 className="text-2xl mb-[1em]">Using this page</h2>

        <ul className="list-disc list-inside mb-[1em]">
          <li>Click on the order number to see the order lines</li>
        </ul>

        <div className="flex justify-left">
          <div className="h-[1.5em] w-[1.5em] bg-calendar bg-contain mr-[.5em]"></div>
          Get data from a different date-range
        </div>
        <div className="flex justify-left">
          <div className="h-[1.5em] w-[1.5em] bg-refresh bg-contain mr-[.5em]"></div>
          Refresh the data
        </div>
        <div className="flex justify-left">
          <div className="h-[1.5em] w-[1.5em] bg-download bg-contain mr-[.5em]"></div>
          Export the data to a .csv file
        </div>
      </div>
    </>
  );
}


function InvoiceHeadersInfo() {
  return (
    <>
      <div className="mb-[1em]">
        <h2 className="text-2xl mb-[1em]">Page Info</h2>
        <p>This page shows invoices from the selected date-range.</p>
        <p>Data is updated daily at 8am, 11am, 3pm & 7pm.</p>
      </div>

      <div>
        <h2 className="text-2xl mb-[1em]">Using this page</h2>

        <ul className="list-disc list-inside mb-[1em]">
          <li>Click on the invoice number to see the invoice lines</li>
        </ul>

        <div className="flex justify-left">
          <div className="h-[1.5em] w-[1.5em] bg-calendar bg-contain mr-[.5em]"></div>
          Get data from a different date-range
        </div>
        <div className="flex justify-left">
          <div className="h-[1.5em] w-[1.5em] bg-refresh bg-contain mr-[.5em]"></div>
          Refresh the data
        </div>
        <div className="flex justify-left">
          <div className="h-[1.5em] w-[1.5em] bg-download bg-contain mr-[.5em]"></div>
          Export the data to a .csv file
        </div>
      </div>
    </>
  );
}




function SalesOrderLinesInfo() {
  return (
    <>
      <div className="mb-[1em]">
        <h2 className="text-2xl mb-[1em]">Page Info</h2>
        <p>This page shows lines for the selected sales order.</p>
        <p>Data is updated daily at 8am, 11am, 3pm & 7pm.</p>
      </div>

      <div>
        <h2 className="text-2xl mb-[1em]">Using this page</h2>

        <div className="flex justify-left">
          <div className="h-[1.5em] w-[1.5em] bg-refresh bg-contain mr-[.5em]"></div>
          Refresh the data
        </div>
        <div className="flex justify-left">
          <div className="h-[1.5em] w-[1.5em] bg-download bg-contain mr-[.5em]"></div>
          Export the data to a .csv file
        </div>
      </div>
    </>
  );
}


function InvoiceLinesInfo() {
  return (
    <>
      <div className="mb-[1em]">
        <h2 className="text-2xl mb-[1em]">Page Info</h2>
        <p>This page shows lines for the selected invoice.</p>
        <p>Data is updated daily at 8am, 11am, 3pm & 7pm.</p>
      </div>

      <div>
        <h2 className="text-2xl mb-[1em]">Using this page</h2>

        <div className="flex justify-left">
          <div className="h-[1.5em] w-[1.5em] bg-refresh bg-contain mr-[.5em]"></div>
          Refresh the data
        </div>
        <div className="flex justify-left">
          <div className="h-[1.5em] w-[1.5em] bg-download bg-contain mr-[.5em]"></div>
          Export the data to a .csv file
        </div>
      </div>
    </>
  );
}

