function H1({text}) {
  return (
    <div className="mb-2">
      <h1 className="text-4xl font-medium">
        {text}
      </h1>
    </div>
  );
}

export default H1;
