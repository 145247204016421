import { useEffect, useState } from "react";
import { useAccessToken } from "../hooks";

const backend = process.env.REACT_APP_API_URL;

function Contract() {

  const getAccessToken = useAccessToken();

  const [token, setToken] = useState(null);

  useEffect(() => {
    async function getTokenOnLoad() {
      const t = await getAccessToken();
      setToken(t);
    }
    if (!token) {
      getTokenOnLoad();
    }
  }, [getAccessToken, token]);

  return (
    <div className="h-full w-full">
      {token &&
        <iframe
          title="Contract"
          className="h-full w-full"
          src={backend + "/contract?token=" + token}
        />
      }
    </div>
  );
}

export default Contract;
