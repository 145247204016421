import { Button1 } from './Buttons';
import H2 from './H2';

export function ApiError({ errorCode, okFunc }) {

  const message =
    errorCode === 999 ? "Could not reach the server. Please check your network connection." :
    errorCode === 500 ? "Server error" :
    errorCode === 400 ? "Client error" :
    errorCode === 401 ? "Access denied" :
    "Unknown error";

  return (
    <div className="h-full max-w-[30em] mx-auto flex flex-col justify-center">
      <div className="w-full h-auto bg-bgMain p-[1em] rounded-xl text-txtMain drop-shadow-2xl">
        <H2 text="Error"/>
        <div>Code: {errorCode}</div>
        <div>Message:</div>
        <div>{message}</div>
        <Button1 onClick={okFunc} text="Ok" />
      </div>
    </div>
  );
}