import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  invoiceNo: '',
  get: true,
  error: 0,
};

export const invLinesSlice = createSlice({
  name: 'invLines',
  initialState: initialState,
  reducers: {
    invLinesData: (state, action) => {
      state.data = action.payload;
    },
    invLinesNo: (state, action) => {
      state.invoiceNo = action.payload;
    },
    invLinesGet: (state, action) => {
      state.get = action.payload;
    },
    invLinesError: (state, action) => {
      state.error = action.payload;
    },
    invLinesReset: (state) => {
      state.data = null;
      state.invoiceNo = '';
      state.get = true;
      state.error = 0;
    },
  },
});

export const {
  invLinesData,
  invLinesNo,
  invLinesGet,
  invLinesError,
  invLinesReset,
} = invLinesSlice.actions;

export default invLinesSlice.reducer;

