import { createSlice } from '@reduxjs/toolkit';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    data: null,
    get: true,
    error: 0,
  },
  reducers: {
    profileData: (state, action) => {
      state.data = action.payload;
    },
    profileGet: (state, action) => {
      state.get = action.payload;
    },
    profileError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  profileData,
  profileGet,
  profileError,
} = profileSlice.actions;

export default profileSlice.reducer;
