import { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { useAccessToken } from '../hooks';
import H1 from './reusable/H1';
import Api from '../Api';
import {
	profileData,
	profileError,
	profileGet,
} from '../redux/profileSlice';
import Spinner from './reusable/Spinner';
import { ApiError } from './reusable/Error';
import { overviewData, overviewError, overviewGet } from '../redux/overviewSlice';
import Spacer from './reusable/Spacer';



Chart.register(CategoryScale);



function Overview() {
  const getAccessToken = useAccessToken();
  const dispatch = useDispatch();

	const data = useSelector((state) => state.overview.data);
	const get = useSelector((state) => state.overview.get);
	const error = useSelector((state) => state.overview.error);

	const inTrendChartData = useMemo(() => {
		return {
			labels: data?.trends?.map(d => d?.Month),
			datasets: [
				{
					label: 'Inbound (9L)',
					data: data?.trends?.map(d => d?.In9L),
					backgroundColor: "#076b86",
					labelColor: "#076b86",
					borderColor: "#076b86",
					borderWidth: 2
				},
				{
					label: 'Outbound (9L)',
					data: data?.trends?.map(d => d?.Out9L),
					backgroundColor: "#ff6666",
					labelColor: "#ff6666",
					borderColor: "#ff6666",
					borderWidth: 2
				},
			],
		};
	}, [data]);

	const options = useMemo(() => {
		return {
			scales: {
				y: {
					grid: { color: '#83b5c3' },
					ticks: { color: '#076b86' },
				},
				x: {
					grid: { color: '#83b5c3' },
					ticks: { color: '#076b86' },
				},
			},
			plugins: {
				title: {
					display: true,
					text: 'Inbound & Outbound Volumes (9L)',
					color: '#076b86',
				},
				legend: { display: false },
			}
		};
	}, []);

	useEffect(() => {

		async function getData() {
			const token = await getAccessToken();
			const res = await Api.GET('/transactions/overview', { accessToken: token });
			if (res.status === 200) {
				dispatch(overviewData(res?.data));
			} else {
				dispatch(overviewError(res.status));
			}
		}
		if (get) {
			dispatch(overviewData(null));
			dispatch(overviewGet(false));
			getData();
		}
	}, [get, dispatch, getAccessToken]);

	return (
		<div className='mt-[1em] mb-[2em] bg-bgTblRow2 p-[1em] rounded-xl border-2 border-bdrTblRows text-txtTblData drop-shadow-xl'>

			<h2 className='w-full text-center mb-[1em] font-bold underline'>Operations Overview</h2>

			<div className='flex justify-around h-fit w-full'>
				{
					error > 0 ?
						<div className='h-fit'>
							<ApiError errorCode={error} okFunc={() => dispatch(overviewError(0))}/>
						</div>
					: !data ?
						<div className='h-[5em] w-full flex flex-col justify-center'>
							<Spinner />
						</div>
					: (<>

							<div className='w-fit h-auto border-2 border-bdrTblRows rounded-xl bg-bgMain px-[1em] flex flex-col justify-center drop-shadow-lg'>
								<h2 className='text-center underline font-bold mb-[1em]'>Current Stock (9L)</h2>
								<table className='w-full text-left mb-[1em]'><tbody>
									<tr>
										<th className="pr-[2em]">Volume</th>
										<td className="text-right">{data?.current_stock_volume?.toFixed(0)}</td>
									</tr>
								</tbody></table>
								<h2 className='text-center underline font-bold mb-[1em] border-t-2 border-bdrTblRows pt-[.5em]'>7 Days (9L)</h2>
								<table className='w-full text-left mb-[1em]'><tbody>
									<tr>
										<th className="pr-[2em]">Inbound</th>
										<td className="text-right">{data?.volumes_7d?.In9L?.toFixed(0)}</td>
									</tr>
									<tr className='text-txtAlert'>
										<th className="pr-[2em]">Outbound</th>
										<td className="text-right">{data?.volumes_7d?.Out9L?.toFixed(0)}</td>
									</tr>
								</tbody></table>
								<h2 className='text-center underline font-bold mb-[1em] border-t-2 border-bdrTblRows pt-[.5em]'>30 Days (9L)</h2>
								<table className='w-full text-left mb-[1em]'><tbody>
									<tr>
										<th className="pr-[2em]">Inbound</th>
										<td className="text-right">{data?.volumes_30d?.In9L?.toFixed(0)}</td>
									</tr>
									<tr className='text-txtAlert'>
										<th className="pr-[2em]">Outbound</th>
										<td className="text-right">{data?.volumes_30d?.Out9L?.toFixed(0)}</td>
									</tr>
								</tbody></table>
							</div>


							<div className='w-[60%] h-auto border-2 border-bdrTblRows rounded-xl bg-bgMain drop-shadow-lg'>
								<Line
									data={inTrendChartData}
									options={options}
								/>
							</div>
						
						


					</>)
				}
			</div>
		</div>
	)
}







function AccountDetails() {
  const getAccessToken = useAccessToken();
  const dispatch = useDispatch();
	const data = useSelector((state) => state.profile.data);
	const get = useSelector((state) => state.profile.get);
	const error = useSelector((state) => state.profile.error);

	useEffect(() => {
		async function getData() {
			const token = await getAccessToken();
			const res = await Api.GET('/profile', { accessToken: token });
			if (res.status === 200) {
				dispatch(profileData(res?.data));
			} else {
				dispatch(profileError(res.status));
			}
		}

		if (get) {
			dispatch(profileGet(false));
			getData();
		}
	}, [dispatch, getAccessToken, get]);

	return (
		<div className="min-h-[5em] bg-bgTblRow2 mt-[1em] mb-[2em] p-[1em] rounded-xl border-2 border-bdrTblRows text-txtTblData drop-shadow-xl">
			<h2 className='w-full text-center mb-[1em] font-bold underline'>General Information</h2>

			<div className='flex justify-around h-fit w-full'>

				{
					error > 0 ?
						<div className='h-fit'>
							<ApiError errorCode={error} okFunc={() => dispatch(profileError(0))}/>
						</div>
					: !data ?
						<div className='h-[5em] w-full flex flex-col justify-center'>
							<Spinner />
						</div>
					: (<>

							<div className='w-fit h-auto border-2 border-bdrTblRows rounded-xl bg-bgMain p-[1em] flex flex-col justify-center drop-shadow-lg'>
								<h2 className='text-center underline font-bold mb-[1em]'>Contact Us</h2>
								<table className='text-left'><tbody>
									<tr className=''>
										<th className="pr-[2em] align-top">Account Manager</th>
										<td className="">
											<div>{data?.SalespersonName}</div>
											<div><a href={'mailto:' + (data?.SalespersonEmail ?? '')}>{data?.SalespersonEmail}</a></div>
											<div>{data?.SalespersonPhoneNo}</div>
										</td>
									</tr>
									<tr className='h-[1em]'></tr>
									<tr>
										<th className="pr-[2em] align-top">Order Queries</th>
										<td className="">
											<div><a href='mailto:customerservices@libertywines.co.uk'>customerservices@libertywines.co.uk</a></div>
											<div>02077205350</div>
										</td>
									</tr>
									<tr className='h-[1em]'></tr>
									<tr>
										<th className="pr-[2em] align-top">Invoicing Queries</th>
										<td className="">
											<div><a href='mailto:lwinvoices@libertywines.co.uk'>lwinvoices@libertywines.co.uk</a></div>
											<div>02078190323</div>
										</td>
									</tr>
									<tr className='h-[1em]'></tr>
									<tr>
										<th className="pr-[2em] align-top max-w-[15em]">Login/App Queries</th>
										<td className="">
											<div><a href='mailto:apps@libertywines.co.uk'>apps@libertywines.co.uk</a></div>
											<div>00000000000</div>
										</td>
									</tr>
								</tbody></table>
							</div>

					</>)
				}
			</div>
		</div>
	);
}









function Profile() {

  return (
    <div className="h-full w-full overflow-y-auto">
			<Spacer />
			<div className='mb-[2em]'>
				<H1 text="Profile" />
			</div>
			<Overview />
			<AccountDetails />
    </div>
  );
}

export default memo(Profile);
