

function MyDatePicker({ label, value, onChange }) {

  return (
    <div className="w-full flex justify-between">
      <label>{label}</label>
      <input
        type="date"
        className="border-2 border-bdrTblRows rounded-md"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}

export default MyDatePicker;
