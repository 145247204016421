import axios from 'axios';

const backend = process.env.REACT_APP_API_URL;

const Api = {
  GET: async (endpoint, options) => {
    const res = await axios({
      method: 'get',
      url: `${backend}${endpoint}`,
      options: options
    });
    return res;
  },

  POST: async (endpoint, options) => {
    const res = await axios({
      method: 'post',
      url: `${backend}${endpoint}`,
      options: options,
    });
    return res;
  }
};

export default Api;
