import spinner from '../../resources/spinner-two.png';

function Spinner() {
  return (
    <div className="h-full w-full relative">
      <div className="absolute top-0 left-0 h-full w-full z-40 flex justify-center bg-bgSpinner/50 backdrop-blur-sm">
        <div className="w-fit h-full flex flex-col justify-center">
          <div className="w-full h-fit">
            <img src={spinner} alt="Loading" className="w-[3em] h-auto animate-spin"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Spinner;
