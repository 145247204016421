import { createSlice } from '@reduxjs/toolkit';

export const viewHistorySlice = createSlice({
  name: 'viewHistory',
  initialState: {
    history: [null, null],
  },
  reducers: {
    newView: (state, action) => {
      state.history = [action.payload, state.history[0]];
    }
  },
});

export const {
  newView,
} = viewHistorySlice.actions;

export default viewHistorySlice.reducer;

