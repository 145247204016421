import { createSlice } from '@reduxjs/toolkit';

export const stockSlice = createSlice({
  name: 'stock',
  initialState: {
    data: null,
    get: true,
    error: 0,
  },
  reducers: {
    stockData: (state, action) => {
      state.data = action.payload;
    },
    stockGet: (state, action) => {
      state.get = action.payload;
    },
    stockError: (state, action) => {
      state.error = action.payload;
    },
    stockReset: (state) => {
      state.data = null;
      state.get = true;
      state.error = 0;
    },
  },
});

export const {
  stockData,
  stockGet,
  stockError,
  stockReset,
} = stockSlice.actions;

export default stockSlice.reducer;
