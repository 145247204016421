import { createSlice } from '@reduxjs/toolkit';

function getInitDates() {
  const _end = new Date();
  const endDay = String(_end.getDate()).padStart(2, '0');
  const endMon = String(_end.getMonth() + 1).padStart(2, '0');
  const endYear = _end.getFullYear();

  // Last 30 days (including today) of data by default.
  const _start = new Date(_end - 29 * 24 * 60 * 60 * 1000);
  const startDay = String(_start.getDate()).padStart(2, '0');
  const startMon = String(_start.getMonth() + 1).padStart(2, '0');
  const startYear = _start.getFullYear();

  const __start = `${startYear}-${startMon}-${startDay}`;
  const __end = `${endYear}-${endMon}-${endDay}`;
  return [__start, __end];
}

function getInit() {
  const [ start, end ] = getInitDates();
  const initialState = {
    data: null,
    start: start,
    end: end,
    get: true,
    error: 0,
  };
  return initialState;
}

export const soHdrsSlice = createSlice({
  name: 'soHdrs',
  initialState: getInit(),
  reducers: {
    soHdrsData: (state, action) => {
      state.data = action.payload;
    },
    soHdrsGet: (state, action) => {
      state.get = action.payload;
    },
    soHdrsError: (state, action) => {
      state.error = action.payload;
    },
    soHdrsDateRange: (state, action) => {
      state.start = action.payload.start;
      state.end = action.payload.end;
    },
    soHdrsReset: (state) => {
      state.data = null;
      state.get = true;
      state.error = 0;
      const [start, end] = getInitDates();
      state.start = start;
      state.end = end;
    },
  },
});

export const {
  soHdrsData,
  soHdrsGet,
  soHdrsError,
  soHdrsDateRange,
  soHdrsReset,
} = soHdrsSlice.actions;

export default soHdrsSlice.reducer;
