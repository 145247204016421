import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';



export function useLogout() {
  const {
    logout,
  } = useAuth0();
  const logoutFunc = useCallback(() => {
    logout();
  }, [logout]);
  return logoutFunc;
}



export function useAccessToken() {
  const {
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();

  async function getAccessToken() {

    const authParams = {
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      //scope: 'read:root',
    };

    let token = null;
    try {
      token = await getAccessTokenSilently({
        authorizationParams: authParams,
      });
    } catch (err) {
      token = await getAccessTokenWithPopup({
        authorizationParams: authParams,
      });
    }

    console.log(token);
    return token;
  }

  return getAccessToken;
}

