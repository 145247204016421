function H2({text}) {
  return (
    <div className="">
      <h2 className="text-txtMain text-2xl">
        {text}
      </h2>
    </div>
  );
}

export default H2;
