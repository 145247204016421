import { useState } from 'react';
import { CSVLink } from 'react-csv';

import plusImg from '../../resources/add-r.png';
import minusImg from '../../resources/remove-r.png';
import reloadSymbol from "../../resources/redo.png";
import calendarSymbol from "../../resources/calendar-dates.png";
import download from '../../resources/push-down.png';
import info from '../../resources/info.png';
import close from '../../resources/close-o.png';


export function Button1({text, onClick}) {
  return (
    <div className="w-full">
      <button
        className="bg-bgButton hover:bg-bgButtonHov text-txtButton w-full rounded-md px-[.25em]"
        onClick={onClick}
      >{text}</button>
    </div>
  );
}


export function ApplyFilterButton({onClick, isApplied}) {
  const className = isApplied ?
  "bg-bgRedButtonHov h-full w-full rounded-md px-[0.25em]" :
  "bg-bgRedButton hover:bg-bgRedButtonHov h-full w-full rounded-md px-[0.25em]";

  const imgClassName = "h-[1.25em] w-auto";

  return (
    <div className="w-fit">
      <button
        className={className}
        onClick={onClick}
      >
        {
          isApplied ?
          <img src={minusImg} className={imgClassName} alt="Clear" /> :
          <img src={plusImg} className={imgClassName} alt="Apply" />
        }
      </button>
    </div>
  );
}


export function IconButton({onClick, icon}) {

  const img = icon === "refresh" ? reloadSymbol :
    icon === "calendar" ? calendarSymbol :
    icon === "info" ? info :
    icon === "close" ? close :
    null;

  const text = icon === "refresh" ? "Refresh data" :
    icon === "calendar" ? "Select date range" :
    icon === "info" ? "Info" :
    icon === "close" ? "Close" :
    null;

  const [isDropped, setIsDropped] = useState(false);

  return(
    <div className="h-full flex flex-col justify-center relative">
      <div className="flex justify-center">
        <button className="h-[2em]" onClick={onClick} onMouseEnter={() => setIsDropped(true)} onMouseLeave={() => setIsDropped(false)} >
          <img src={img} alt={text} className="h-full w-auto"/>
        </button>
        {isDropped ?
          <div className="absolute z-50 top-[-30px] w-max bg-bgTblHdr text-txtTblHdr p-[.1em] rounded-md">
            {text}
          </div>
        : <></>}
      </div>
    </div>
  );
}


export function ExportCSV({data, headers, fileName}) {
  const [isDropped, setIsDropped] = useState(false);
  return (
    <div className="h-full flex flex-col justify-center relative">
      <div className="flex justify-center">
        <button className="h-[2em]" onMouseEnter={() => setIsDropped(true)} onMouseLeave={() => setIsDropped(false)} >
            <CSVLink data={data} headers={headers} filename={fileName}>
              <img src={download} alt="Export to csv" className="h-[2em] w=auto" />
            </CSVLink>
        </button>
        {isDropped ?
          <div className="absolute z-50 top-[-30px] w-max bg-bgTblHdr text-txtTblHdr p-[.1em] rounded-md">
            Export CSV
          </div>
        : <></>}
      </div>
    </div>
  );
}

