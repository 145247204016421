import { useAuth0 } from '@auth0/auth0-react';
import { Button1 } from './reusable/Buttons';
import logo from '../resources/white-logo.png';


function Login() {
  const {
    loginWithRedirect,
  } = useAuth0();

  return (
    <div className="h-full w-full">
      <div className="h-full w-full bg-bgBlur/20">
        <div className="h-full max-w-[30em] mx-auto flex flex-col justify-center">
          <div className="w-full h-auto">

            <div className="w-full">

              <div className="h-[4em] bg-bgNavHdr1 p-[1em] rounded-t-xl border border-[white]">
                <div className="h-full w-fit flex justify-center flex-col mx-auto">
                  <img src={logo} alt="Logo" className="h-[90%] w-auto" />
                </div>
              </div>

              <div className="p-[1em] bg-bgMain rounded-b-xl text-txtMain">
                <div className="">
                  <div className="mt-[1em]">
                    <Button1 text='Login' onClick={loginWithRedirect} />
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
