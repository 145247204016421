import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  orderNo: '',
  get: true,
  error: 0,
};

export const soLinesSlice = createSlice({
  name: 'soLines',
  initialState: initialState,
  reducers: {
    soLinesData: (state, action) => {
      state.data = action.payload;
    },
    soLinesNo: (state, action) => {
      state.orderNo = action.payload;
    },
    soLinesGet: (state, action) => {
      state.get = action.payload;
    },
    soLinesError: (state, action) => {
      state.error = action.payload;
    },
    soLinesReset: (state) => {
      state.data = null;
      state.orderNo = '';
      state.get = true;
      state.error = 0;
    },
  },
});

export const {
  soLinesData,
  soLinesNo,
  soLinesGet,
  soLinesError,
  soLinesReset,
} = soLinesSlice.actions;

export default soLinesSlice.reducer;
