import { createSlice } from '@reduxjs/toolkit';

const initialData = {
  lines: [],
  deliveryDate: '',
  customerName: '',
};

export const soRequestSlice = createSlice({
  name: 'soRequest',
  initialState: {
    data: initialData,
    status: 'requested',
    error: 0,
  },
  reducers: {
    soRequestAddLine: (state, action) => {
      const arr = [];
      for (let i = 0; i < state.data.lines.length; i++) {
        arr.push(state.data.lines[i]);
      }
      arr.push({
        sku: action.payload.sku,
        uom: action.payload.uom,
        qty: action.payload.qty,
      });
      state.data.lines = arr;
    },
    soRequestDelLine: (state, action) => {
      const idx = action.payload;
      const firstPart = state.data.lines.slice(0, idx);
      const secondPart = state.data.lines.slice(idx + 1);
      state.data.lines = firstPart.concat(secondPart);
    },
    soRequestCust: (state, action) => {
      state.data.customerName = action.payload;
    },
    soRequestDeliveryDate: (state, action) => {
      state.data.deliveryDate = action.payload;
    },
    soRequestReset: (state) => {
      state.data = initialData;
      state.status = 'requested';
      state.error = 0;
    },
    soRequestStatus: (state, action) => {
      state.status = action.payload;
    },
    soRequestError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  soRequestAddLine,
  soRequestDelLine,
  soRequestCust,
  soRequestDeliveryDate,
  soRequestReset,
  soRequestError,
  soRequestStatus,
} = soRequestSlice.actions;

export default soRequestSlice.reducer;

