import { useMemo } from 'react';
import axios from 'axios';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';

import { useLogout } from './hooks';
import Login from './components/Login';
import LoggedIn from './components/LoggedIn';

function generateQueryString(params) {
  if (params.length === 0) {
    return '';
  }

  let str = '?';
  params.map(({key, value}) => {
    str = `${str}${key}=${value}&`;
    return str;
  });
  str = str.substring(0, str.length - 1);
  return str;
}

function App() {

  const {
    isAuthenticated,
  } = useAuth0();


  /* ========== Networking ========== */

  const logout = useLogout();

  useMemo(() => {
    // outbound interceptor
    axios.interceptors.request.use(
      (req) => {
        const options = req?.options ?? false;

        if (options === false) {
          return req;
        }

        const accessToken = options?.accessToken ?? false;
        const params = options?.params ?? [];
        const body = options?.body ?? false;


        // access token
        if (accessToken) {
          req.headers['Authorization'] = 'Bearer ' + accessToken;
        }

        // query params
        req.url += generateQueryString(params);

        // request body
        if ((body !== false) && (req?.method === 'post')) {
          req.data = body;
        }

        return req;
      }
    );
  }, []);

  useMemo(() => {
    // inbound interceptor
    axios.interceptors.response.use(
      (res) => {
        return res;
      },
      (err) => {
        /* get rid of all this?
        else if (err?.response?.status === 500) {
          alert('Server error');
        }
        else if (err?.response?.status === 401) {
          alert(String(err?.response?.data) ?? "");
          setJwt('');
        }
        else if (err?.response?.status === 403) {
          alert('Invalid token');
          setJwt('');
        }
        */
        let res = {};
        if (err?.code === 'ERR_NETWORK') {
          res.status = 999;
        } else {
          res.status = err.response.status;
        }

        if (err?.response?.status === 470) {
          alert('Session expired, please login');
          logout();
        }

        return res;
      }
    );
  }, [logout]);

  return (
    <div className="h-screen w-screen bg-bgImg1 bg-cover font-main">
      {isAuthenticated ? (
        <LoggedIn />
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;

