import { createSlice } from '@reduxjs/toolkit';

export const overviewSlice = createSlice({
  name: 'overview',
  initialState: {
    data: null,
    get: true,
    error: 0,
  },
  reducers: {
    overviewData: (state, action) => {
      state.data = action.payload;
    },
    overviewGet: (state, action) => {
      state.get = action.payload;
    },
    overviewError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  overviewData,
  overviewGet,
  overviewError,
} = overviewSlice.actions;

export default overviewSlice.reducer;

